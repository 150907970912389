import { createRouter, createWebHashHistory } from 'vue-router'
import HomePageOne from '../views/home/HomePageOne'
import HomePageTwo from '../views/home/HomePageTwo'
import HomePageThree from '../views/home/HomePageThree'
import HomePageFour from '../views/home/HomePageFour'
import HomePageFive from '../views/home/HomePageFive'
import HomePageSix from '../views/home/HomePageSix'
import HomePageSeven from '../views/home/HomePageSeven'
import HomePageEight from '../views/home/HomePageEight'
import HomePageNine from '../views/home/HomePageNine'
import CreateProduct from '../views/CreateProduct'
import ProductDetails from '../views/ProductDetails'
import Connect from '../views/Connect'
import Collection from '../views/Collection'
import Creators from '../views/Creators'
import AuthorDetails from '../views/AuthorDetails'
import EditProfilePage from '../views/EditProfilePage'
import About from '../views/About'
import Contact from '../views/Contact'
import BlogDetails from '../views/blog/BlogDetails'
import BlogCategoryPosts from '../views/blog/BlogCategoryPosts'
import BlogTagPosts from '../views/blog/BlogTagPosts'
import BlogSingleColumn from '../views/blog/BlogSingleColumn'
import BlogTwoColumn from '../views/blog/BlogTwoColumn'
import BlogThreeColumn from '../views/blog/BlogThreeColumn'
import BlogFourColumn from '../views/blog/BlogFourColumn'
import ExploreOne from '../views/ExploreOne'
import ExploreTwo from '../views/ExploreTwo'
import ExploreThree from '../views/ExploreThree'
import ExploreFour from '../views/ExploreFour'
import ExploreFive from '../views/ExploreFive'
import ExploreSix from '../views/ExploreSix'
import ExploreSeven from '../views/ExploreSeven'
import ExploreEight from '../views/ExploreEight'
import ExploreNine from '../views/ExploreNine'
import ExploreTen from '../views/ExploreTen'
import ExploreEleven from '../views/ExploreEleven'
import ExploreTwelve from '../views/ExploreTwelve'
import ExploreThirteen from '../views/ExploreThirteen'
import UploadVariants from '../views/UploadVariants'
import Activity from '../views/Activity'
import UpcomingProjects from '../views/UpcomingProjects'
import Login from '../views/Login'
import Signup from '../views/Signup'
import ForgetPassword from '../views/ForgetPassword'
import PrivacyPolicy from '../views/PrivacyPolicy'
import Products from '../views/Products'
import Ranking from '../views/Ranking'
import NotFound from '../views/NotFound'
import Support from '../views/Support'
import TermsCondition from '../views/TermsCondition'
import ComingSoon from '../views/ComingSoon'
import Maintenance from '../views/Maintenance'


const routes = [
    {
        path: '/',
        name: 'Home',
        component: About,
        meta: {
            title: 'Artisan - Liberdade para ser criativo, automação para ser eficiente.',
        },
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL), // Usando hash history
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router

