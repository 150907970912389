<template>
    <layout>
        <!-- Start Banner Area -->
        <div class="rn-about-banner-area rn-section-gapTop">
            <div class="container mb--30">
                <div class="row">
                    <div class="col-12">
                        <h2 class="title about-title-m"
                            data-sal="slide-up"
                            data-sal-duration="800"
                            data-sal-delay="150">
                            Liberdade para ser criativo. <br>Automação para ser eficiente.
                        </h2>
                    </div>
                </div>
            </div>
            <div class="container-fluid about-fluidimg fixtop">
                <div class="row">
                    <div class="img-wrapper">
                            <video id="chrome-clip" playsinline autoplay muted loop>
                                <source src="../assets/videos/Header.mp4" type="video/mp4">
                                <p>Seu browser não suporte videos.</p>
                            </video>
                       
                    </div>
                </div>
            </div>
            
            <div class="container">
                <div class="row g-5 fix-m">
                    <div class="col-lg-5">
                        <div class="h--100">
                            <div class="rn-about-card mt_dec--50 widge-wrapper rbt-sticky-top-adjust">
                                <div class="inner">
                                    <h2 class="title"
                                        data-sal="slide-up"
                                        data-sal-duration="800"
                                        data-sal-delay="150">
                                        Escala para todo o tipo de mídia.
                                    </h2>
                                    <p class="about-disc"
                                       data-sal="slide-up"
                                       data-sal-duration="800"
                                       data-sal-delay="150">
                                        <b>Anúncios em sites, Banners HTML5, Dinâmicos, Redes Sociais, Vídeos e DOOH.</b>
                                        <br>
                                        <br>
                                        O Artisan automatiza todas as tarefas de produção de suas campanhas na web para que sua marca possa focar no que realmente importa: o resultado.                                    </p>
                                    <a href="https://wa.me/555332225551"
                                                 class="btn btn-primary-alta btn-large sal-animate mt--20"
                                                 data-sal="slide-up"
                                                 data-sal-duration="800"
                                                 data-sal-delay="150"
                                                 target="_blank">
                                        Fale com a gente.
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="rn-about-card transparent-bg" style="border: none;">
                            <div class="inner">
                                <h3 class="title" data-sal="slide-up" data-sal-duration="800" data-sal-delay="150">
                                    Marcas que escalaram<br>a produção com o Artisan.
                                </h3>
                                <ul class="row row-cols-3 row-cols-sm-3 row-cols-lg-3 row-cols-xl-3 list-unstyled list">
                                    
                                    <li class="col mb-4" data-tags="number numeral" data-categories="shapes">
                                        <a class="d-block text-dark text-decoration-none" href="//www.uber.com.br" target="_blank">
                                            <div class="p-3 py-4 mb-2 bg-light text-center rounded">
                                                <img src="../assets/images/artisan/logo-uber-512.png" alt="Uber">
                                            </div>
                                        </a>
                                    </li>

                                    <li class="col mb-4" data-tags="number numeral" data-categories="shapes">
                                        <a class="d-block text-dark text-decoration-none" href="//www.itau.com.br" target="_blank">
                                            <div class="p-3 py-4 mb-2 bg-light text-center rounded">
                                                <img src="../assets/images/artisan/logo-itau-512.png" alt="Itau">
                                            </div>
                                        </a>
                                    </li>

                                    <li class="col mb-4" data-tags="number numeral" data-categories="shapes">
                                        <a class="d-block text-dark text-decoration-none" href="//www.tim.com.br" target="_blank">
                                            <div class="p-3 py-4 mb-2 bg-light text-center rounded">
                                                <img src="../assets/images/artisan/logo-tim-512.png" alt="TIM">
                                            </div>
                                        </a>
                                    </li>
                                    
                                    <li class="col mb-4" data-tags="number numeral" data-categories="shapes">
                                        <a class="d-block text-dark text-decoration-none" href="//www.mrv.com.br" target="_blank">
                                            <div class="p-3 py-4 mb-2 bg-light text-center rounded">
                                                <img src="../assets/images/artisan/logo-mrv-engenharia-512.png" alt="MRV">
                                            </div>
                                        </a>
                                    </li>

                                    <li class="col mb-4" data-tags="number numeral" data-categories="shapes">
                                        <a class="d-block text-dark text-decoration-none" href="//www.seara.com.br" target="_blank">
                                            <div class="p-3 py-4 mb-2 bg-light text-center rounded">
                                                <img src="../assets/images/artisan/logo-seara-512.png" alt="Seara">
                                            </div>
                                        </a>
                                    </li>

                                    <li class="col mb-4" data-tags="number numeral" data-categories="shapes">
                                        <a class="d-block text-dark text-decoration-none" href="//www.https://www.guaranaantarctica.com.br/" target="_blank">
                                            <div class="p-3 py-4 mb-2 bg-light text-center rounded">
                                                <img src="../assets/images/artisan/guarana-antarctica-512.png" alt="Guaraná Antartica">
                                            </div>
                                        </a>
                                    </li>

                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Banner Area -->

         <!-- Start BLog Area  -->
        <div class="rn-blog-area rn-section-gapTop">
            <div class="container" style="z-index: 99; position: relative;">
                <div class="row mb--50 align-items-center">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                        <h3 class="title mb--0" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            Alguns diferenciais
                        </h3>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15">
                        <div class="view-more-btn text-start text-sm-end"
                             data-sal-delay="150"
                             data-sal="slide-up"
                             data-sal-duration="800">
                            <!-- <router-link class="btn-transparent" to="/blog">
                                VIEW ALL<i class="feather-arrow-right"/>
                            </router-link> -->
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <template v-for="(blog, index) in posts" :key="`blog-${index}`">
                        <div v-if="index < 4"
                             class="col-xl-3 col-lg-4 col-md-6 col-12"
                             data-sal="slide-up"
                             data-sal-duration="800"
                             data-sal-delay="150">
                            <blog-card :blog="blog"/>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!-- End BLog Area  -->

        <!-- Start CTA Area -->
        <div class="rn-callto-action rn-section-gapTop">
            <div class="container-fluid about-fluidimg-cta">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="" data-black-overlay="7">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="call-to-action-wrapper">
                                        <h3 data-sal="slide-up" data-sal-duration="800" data-sal-delay="150">
                                            Entre em contato com nossa equipe de especialistas
                                        </h3>
                                        <p data-sal="slide-up" data-sal-duration="800" data-sal-delay="150">
                                            Temos mais de 15 anos de experiência na criação de campanhas para grandes marcas e construímos o Artisan como uma resposta aos maiores desafios encontrados no processo de produção de campanhas de mídia de todos os formatos.
                                        </p>
                                        <div class="callto-action-btn-wrapper"
                                             data-sal="slide-up"
                                             data-sal-duration="800"
                                             data-sal-delay="150">
                                             <a href="https://wa.me/555332225551"
                                                 class="btn btn-primary-alta btn-large sal-animate mt--20"
                                                 data-sal="slide-up"
                                                 data-sal-duration="800"
                                                 data-sal-delay="150"
                                                 target="_blank">
                                                Fale com a gente.
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End CTA Area -->

       
    </layout>
</template>

<style>
    @media only screen and (min-width: 651px) {
        .fixtop {
            margin-top: -2em;
        }

        .rn-section-gapTop {
            padding-top: 4em !important;
            text-align: center;
        }

        .rn-about-card {
            text-align: left;
        }

        #chrome-clip {
            width: 62%;
            /* position: absolute;
            right: 0; */
        }

        .img-wrapper {
            text-align: center;
        }

        .logo-custom-css {
            padding: 1em 0;;
        }

        .rn-about-card.transparent-bg {
            margin-top: 50px !important;
        }

        .rn-blog-area {
            margin-top: 5em;
        }
    }

    @media only screen and (max-width: 650px) {
        #chrome-clip {
            width: 80%;
            /* position: absolute;
            right: 0; */
        }
        .img-wrapper {
            text-align: center;
        }
        .fix-m {
            margin-top: calc(4 * var(--bs-gutter-y)) !important;
        }
        .header-btn {
            display: none;
        }

        .title {

        }
    }


/* .bg_image {
    height: 300px !important;
    width: 300px !important;
    position: relative;
} */

/* .bg_image video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
} */
</style>

<script>
    import CountUp from 'vue-countup-v3'
    import { Waypoint } from 'vue-waypoint'
    import Layout from "@/components/layouts/Layout";
    import BlogCard from "@/components/blog/BlogCard";
    import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
    import BlogMixin from "@/mixins/BlogMixin";

    export default {
        name: 'About',
        components: {BlogCard, Layout, CountUp, Waypoint},
        mixins: [SalScrollAnimationMixin, BlogMixin],
        data() {
            return {
                startCounter: false
            }
        },
        methods: {
            onChange(waypointState) {
                if (waypointState.going === 'IN') {
                    this.startCounter = true;
                }
            }
        }
    }
</script>
